import * as React from 'react'
import * as css from './ImageAndText.module.css'
import Img from './Img'
import hexLight from 'assets/hex-light.svg'
import clsx from 'clsx'

const Image = ({
	image,
	alt,
	caption,
	className,
	captionStyle,
	grayscale,
	...props
}) => (
	<figure className={clsx(className, css.img, 'relative')}>
		<Img
			className={grayscale && 'grayscale'}
			image={image}
			alt={caption || alt || ''}
			{...props}
		/>

		{!!caption && (
			<figcaption className="location-caption" style={captionStyle}>
				<span className="action">{caption}</span>
			</figcaption>
		)}
	</figure>
)

const Text = ({ className, children }) => (
	<div
		className={clsx(
			className,
			css.txt,
			'ctnr-half richtext relative mx-auto !box-border w-full self-center px-8 py-8',
		)}
	>
		{children}
	</div>
)

function ImageAndText({ className, imageOnRight, showHexPattern, children }) {
	const enableSplit = children?.filter(Boolean).length > 1

	return (
		<section className={clsx(className, 'relative overflow-hidden')}>
			{showHexPattern && (
				<figure className={css.hex}>
					<img
						className="-scale-x-100 transform"
						src={hexLight}
						alt=""
						width={767}
						height={402}
						loading="lazy"
						draggable="false"
					/>
				</figure>
			)}

			<div
				className={clsx(
					css.wrapper,
					enableSplit && [css.grid, 'grid md:grid-cols-2'],
					imageOnRight && css.imgRight,
				)}
			>
				{children}
			</div>
		</section>
	)
}

export { ImageAndText, Image, Text }
