import * as React from 'react'
import { CTA } from './CTALink'
import clsx from 'clsx'

export default function CTAList({ ctas, className, style }) {
	if (!ctas) return null

	return (
		<div
			className={clsx(
				'flex flex-wrap items-center gap-3 <md:*:w-full',
				className,
			)}
			style={style}
		>
			{ctas?.map((cta, key) => (
				<CTA key={key} {...cta} />
			))}
		</div>
	)
}
